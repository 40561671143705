<!-- Header -->
<div class="modal-header">
  <!-- Title -->
  <h5 class="modal-title" translate>FEEDBACK</h5>
  <!-- Close -->
  <button class="ml-auto btn btn-link p-0" (click)="modal.hide()">
    <fa-icon [fixedWidth]="true" [icon]="times"></fa-icon>
  </button>
</div>

<!-- Body -->
<form class="modal-body" [formGroup]="form" (ngSubmit)="submit()">

  <!-- Text -->
  <p translate>FEEDBACK_TEXT</p>

  <!-- Subject -->
  <div class="form-group" *ngIf="!transactionId">
    <label for="subject" translate>SUBJECT</label>
    <div class="btn-group d-flex" btnRadioGroup formControlName="subject">
      <label class="btn btn-light" id="subject"
             [uncheckable]="true" [btnRadio]="subject.value" *ngFor="let subject of subjects">
        <fa-icon [icon]="subject.icon" [fixedWidth]="true" class="mr-2 text-{{ subject.color }}"></fa-icon>
        <span translate>{{ subject.label }}</span>
      </label>
    </div>
  </div>

  <!-- Experience -->
  <div class="form-group" *ngIf="!transactionId">
    <label for="experience" translate>EXPERIENCE</label>
    <div class="btn-group d-flex" btnRadioGroup formControlName="experience">
      <label class="btn btn-light" id="experience" [tooltip]="experience.label | translate"
             [uncheckable]="true" [btnRadio]="experience.value" *ngFor="let experience of experiences">
        <fa-icon [icon]="experience.icon" [fixedWidth]="true" size="2x" class="text-{{ experience.color }}"></fa-icon>
      </label>
    </div>
  </div>

  <!-- Message -->
  <div class="form-group">
    <label for="message" translate>MESSAGE</label>
    <textarea formControlName="message" rows="10" id="message" class="form-control"></textarea>
    <div class="text-danger" *ngIf="error.message">{{ error.message[0] }}</div>
  </div>

  <!-- Error -->
  <p class="text-danger" *ngIf="error.non_field_errors">{{ error.non_field_errors[0] }}</p>
</form>

<!-- Footer -->
<div class="modal-footer">
  <button class="btn btn-link" (click)="modal.hide()" translate>CANCEL</button>
  <button class="btn btn-primary" [disabled]="loading || form.invalid" (click)="submit()" translate>SUBMIT</button>
</div>
