<!-- Main wrapper -->
<div class="d-flex flex-column h-100">

  <!-- Navbar for unverified alert -->
  <div class="unverified-header" *ngIf="user && !user.has_verified_email">
    <div [class.container]="!blogService.currentBlog"
         [class.container-fluid]="blogService.currentBlog">
      <fa-icon [fixedWidth]="true" [icon]="faUnverified"></fa-icon>
      <div class="font-weight-bold ml-3">
        <div translate>TEXT_EMAIL_NOT_VERIFIED_BANNER</div>
        <div class="small font-weight-bold" translate>TEXT_EMAIL_NOT_VERIFIED_BANNER_SUB</div>
      </div>
      <button class="btn btn-light ml-3" (click)="resendVerification()" [disabled]="resending || verificationSent">
        {{ (verificationSent ? 'SENT' : 'RESEND') | translate }}
      </button>
    </div>
  </div>

  <!-- Navbar -->
  <nav class="navbar navbar-expand navbar-dark"
       [class.d-none]="uiStatus === dashUiStatus.HEADER || uiStatus === dashUiStatus.NONE"
       [class.bg-dark]="blogService.currentBlog"
       [class.bg-primary]="!blogService.currentBlog">
    <!-- Container -->
    <div [class.container]="!blogService.currentBlog" [class.container-fluid]="blogService.currentBlog">
      <!-- Brand -->
      <a class="navbar-brand" *ngIf="!blogService.currentBlog" routerLink="/" translate>
        <img src="../assets/img/logo.png" alt="GoNevis">
      </a>
      <!-- Nav -->
      <ul class="navbar-nav" *ngIf="user && user.sites">
        <!-- Blog dropdown -->
        <li class="nav-item dropdown dashboard" dropdown>
          <!-- Current blog -->
          <a class="nav-link dropdown-toggle" dropdownToggle>
            <img *ngIf="blogService.currentBlog && blogService.currentBlog.media.logo"
                 [src]="blogService.currentBlog.media.logo.thumbnail_48x48"
                 [alt]="blogService.currentBlog.title"
                 class="rounded">
            <span *ngIf="blogService.currentBlog && !blogService.currentBlog.media.logo"
                  [title]="'NO_BLOG_LOGO' | translate"
                  class="rounded img-placeholder"></span>
            <span *ngIf="blogService.currentBlog">{{ blogService.currentBlog.title }}</span>
            <span *ngIf="!blogService.currentBlog" translate>DASHBOARD</span>
          </a>
          <!-- Dropdown menu -->
          <ul class="dropdown-menu" *dropdownMenu>
            <!-- Blogs -->
            <li *ngFor="let blog of blogs; let index = index">
              <a class="dropdown-item dropdown-item-img" [routerLink]="['/dash', index]"
                 [class.active]="blogService.currentBlog && blogService.currentBlog.id === blog.id">
                <img *ngIf="blog.media.logo" [src]="blog.media.logo.thumbnail_48x48" [alt]="blog.title"
                     class="rounded shadow-sm">
                <span *ngIf="!blog.media.logo" [title]="'NO_BLOG_LOGO' | translate"
                      class="rounded shadow-sm img-placeholder"></span>
                <span>{{ blog.title }}</span>
              </a>
            </li>
            <!-- Separator -->
            <li class="dropdown-divider" *ngIf="blogs && blogs.length"></li>
            <!-- New blog -->
            <li>
              <a class="dropdown-item" routerLink="/user/start" routerLinkActive="active" translate>
                START_NEW_BLOG
              </a>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Search -->
      <form class="form-inline ml-auto" *ngIf="searchStatus" [formGroup]="formSearch" (ngSubmit)="onSearch()"
            dropdown container="#search-form" id="search-form">
        <input class="form-control" type="search" placeholder="Search" formControlName="search" dropdownToggle required>
        <ul class="dropdown-menu" *dropdownMenu [class.d-none]="!searchSuggestions || !searchSuggestions.length">
          <li *ngFor="let suggestion of searchSuggestions">
            <a class="dropdown-item" (click)="onSearchSuggestionClick(suggestion)">{{ suggestion }}</a>
          </li>
        </ul>
      </form>
      <!-- Right Navs -->
      <ul class="navbar-nav" *ngIf="user" [class.ml-auto]="!searchStatus">
        <!-- Feed -->
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/feed/">
            <fa-icon class="mr-1" [fixedWidth]="true" [icon]="faFeed"></fa-icon>
            <span translate>FEED</span>
          </a>
        </li>
        <!-- Abandoned transactions -->
        <li class="nav-item dropdown" dropdown>
          <a class="nav-link" dropdownToggle
             *ngIf="abandonedTransaction && blogService.currentBlog">
            <fa-icon class="mr-1" [fixedWidth]="true" [icon]="faShoppingCart"></fa-icon>
            <span translate>ABANDONED_TRANSACTION</span>
          </a>
          <!-- Dropdown menu -->
          <div class="dropdown-menu" *dropdownMenu>
            <!-- Transaction name and date -->
            <div class="d-flex px-3 flex-column transaction-info w-100" *ngIf="abandonedTransaction"
                 (click)="goToAbandonedTransaction()">
              {{ abandonedTransaction.transaction.plan.name }}
              <span class="mat-caption mat-hint mt-1">{{ abandonedTransaction.created | date }}</span>
            </div>
          </div>
        </li>
        <!-- Feedback -->
        <li class="nav-item" (click)="feedback()">
          <a class="nav-link">
            <fa-icon class="mr-1" [fixedWidth]="true" [icon]="faFeedback"></fa-icon>
            <span translate>FEEDBACK</span>
          </a>
        </li>
        <!-- User dropdown -->
        <li class="nav-item dropdown" dropdown>
          <!-- Dropdown label -->
          <a class="nav-link dropdown-toggle" id="user-dropdown" dropdownToggle>
            <!-- User avatar -->
            <app-user-avatar [name]="user.name" [media]="user.media" size="32" class="mr-1"></app-user-avatar>
            <!-- User name -->
            <span>{{ user.name.split(' ')[0] || user.username }}</span>
          </a>
          <!-- User dropdown -->
          <ul class="dropdown-menu dropdown-menu-right" *dropdownMenu>
            <!-- Profile -->
            <li>
              <a [routerLink]="['/feed/user', user.username]" routerLinkActive="active" class="dropdown-item">
                <fa-icon [fixedWidth]="true" [icon]="faProfile"></fa-icon>
                <span translate>PROFILE</span>
              </a>
            </li>
            <!-- Settings -->
            <li>
              <a routerLink="/user/setting" routerLinkActive="active" class="dropdown-item">
                <fa-icon [fixedWidth]="true" [icon]="faSettings"></fa-icon>
                <span translate>SETTING</span>
              </a>
            </li>
            <!-- Separator -->
            <li class="dropdown-divider"></li>
            <!-- Sign out -->
            <li>
              <button class="dropdown-item" (click)="signOut()">
                <fa-icon [fixedWidth]="true" [icon]="faSignOut"></fa-icon>
                <span translate>SIGN_OUT</span>
              </button>
            </li>
          </ul>
        </li>
      </ul>
      <!-- Navs when not authenticated (right) -->
      <ul class="navbar-nav ml-auto" *ngIf="!user">
        <!-- Sign in -->
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/user/sign-in" translate>SIGN_IN</a>
        </li>
        <!-- Sign up -->
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/user/sign-up" translate>JOIN_GONEVIS</a>
        </li>
      </ul>
    </div>
  </nav>

  <!-- Page -->
  <div class="overflow-auto flex-grow-1 main-scroller">
    <router-outlet></router-outlet>
  </div>
</div>

<!-- Loading bar -->
<ngx-loading-bar [includeSpinner]="false" color="white"></ngx-loading-bar>
