import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DomSanitizer, SafeStyle, SafeHtml } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class UtilService {

  constructor(private domSanitizer: DomSanitizer) {
  }

  /**
   * Build query params based on given source object.
   *
   * @param source Source to build {@link HttpParams} from it
   *
   * @returns HttpParams built based on given source
   */
  static buildParams(source: any): HttpParams {
    /**
     * An HTTP request/response body that represents serialized parameters,
     * per the MIME type `application/x-www-form-urlencoded`.
     *
     * @see HttpParams
     */
    let httpParams: HttpParams = new HttpParams();
    Object.keys(source).forEach((key: string): void => {
      /**
       * Value of the key.
       */
      const value: string | number | boolean | Date = source[key];
      /**
       * If value actually had value, then append it to http params.
       */
      if ((typeof value !== 'undefined') && (value !== null) && (value !== '')) {
        httpParams = httpParams.append(key, value.toString());
      }
    });
    return httpParams;
  }

  /**
   * @returns Offset number by getting page size and current page
   *
   * @param pageSize Maximum items per page
   * @param page Current page number
   */
  static getPageOffset(pageSize: number, page: number): string {
    return `${pageSize * (page - 1)}`;
  }

  /**
   * Bypass security and trust the given value to be safe style value (CSS).
   *
   * @param url Image URL
   * @returns Trusted style URL or nothing of not provided
   */
  sanitizeBackgroundImage(url: string): SafeStyle | null {
    if (url) {
      return this.domSanitizer.bypassSecurityTrustStyle(`url(${url})`);
    }
    return null;
  }

  /**
   * Bypass security and trust the given value to be safe HTML.
   *
   * @param html HTML
   */
  sanitizeHtml(html: string): SafeHtml {
    return this.domSanitizer.bypassSecurityTrustHtml(html);
  }
}
