<!-- Header -->
<div class="modal-header" *ngIf="title">
  <!-- Title -->
  <h5 class="modal-title">{{ title | translate | titlecase }}</h5>
  <!-- Close -->
  <button type="button" class="close pull-right" (click)="modal.hide()">&times;</button>
</div>

<!-- Body -->
<div class="modal-body">
  <p *ngFor="let message of messages; let last = last" [class.mb-0]="last">{{ message | translate }}</p>
</div>

<!-- Footer -->
<div class="modal-footer" *ngIf="button">
  <button class="btn btn-light" (click)="modal.hide()">{{ button | translate }}</button>
</div>
